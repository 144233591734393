<template>
  <div>
    <div class="box">
      <div>订单号：{{ form.orderId}}</div>
      <div class="m-t2">原录单员：{{ form.order?.salesman}}</div>
    </div>
    <div class="box">
      <div>更改结果：</div>
      <div class="m-t1">录单员：{{ form.userName     }}</div>
      <div class="m-t1">原因：{{ form.reason }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  form: {
    type: Object,
    default: {}
  }
})
</script>

<style lang="less" scoped>
.box {
  padding: 20px 10px;
  border-bottom: 1px solid #ccc;
}
</style>