<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <SearchList :loading="loading" :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    </template>
    <a-table :dataSource="dataSource" :columns="columns" :rowKey="(record, index) => { return index }" :pagination="pagination" @change="handleTableChange" :loading="loading">
      <template #action="{ record }">
        <a v-if="record.status.value === 0" @click="opsCheck(record.id,record)">处理</a>
      </template>
    </a-table> 
  </suy-table>
  <a-drawer v-model:visible="visible" width="720" title="审核" maskClosable="true">
    <template v-if="subType === 8">
      <UpVehicle :form="form"></UpVehicle>
    </template>
    <template v-if="subType === 22">
      <UpCustomer :form="form"></UpCustomer>
    </template>
    <template v-if="subType === 19">
      <UpVehiclefee :form="form"></UpVehiclefee>
    </template>
    <template v-if="subType === 26">
      <UpRecordMan :form="form"></UpRecordMan>
    </template>
    <template v-if="subType === 25">
      <UpSourceMan :form="form"></UpSourceMan>
    </template>
    <div class="m-t2 m-b2">
      审核
    </div>
    <a-form-item label="审核结果">
      <a-select v-model:value="form.audit" style="width: 200px;">
        <a-select-option :value="1">同意</a-select-option>
        <a-select-option :value="2">拒绝</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="审核原因">
      <a-textarea v-model:value="form.remark" :rows="3" :maxlength="1000" showCount />
    </a-form-item>
    <span style="margin-left: 70px;">
      <a-button @click="visible = false" class="m-r1">取消</a-button>
      <a-popconfirm title="确定同意? " @confirm="audit">
        <a-button :loading="loadingBtn" type="primary">确定</a-button>
      </a-popconfirm>
    </span>
  </a-drawer>
</template>
<script>
import SuyTable from '@/components/SuyTable'
import { reactive, toRefs , ref} from 'vue'
import { opsPage, query, editAudit } from '@/api/trans/ops/edit'
import { detail } from '@/api/trans/order'
import { onMounted } from 'vue'
import { message } from "ant-design-vue";
import { amountUppercase } from "@/utils/util";
import UpVehicle from './components/upVehicle'
import UpCustomer from './components/upCustomer'
import UpVehiclefee from './components/upVehiclefee'
import UpSourceMan from './components/upSourceMan'
import UpRecordMan from './components/upRecordMan'
import SearchList from '@/components/searchList'
export default {
  components: {
    SuyTable,
    UpVehicle,
    UpCustomer,
    UpVehiclefee,
    UpSourceMan,
    UpRecordMan,
    SearchList
  },
  setup () {
    const state = reactive({
      columns: [
        {
          title: '发起人',
          dataIndex: 'sponsor',
          key: 'sponsor'
        },
        {
          title: '发起时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '分类',
          dataIndex: 'subType.label',
          key: 'subType.label'
        },
        {
          title: '修改原因',
          dataIndex: 'reason',
          key: 'reason'
        },
        {
          title: '状态',
          dataIndex: 'status.label',
          key: 'status.label'
        },
        {
          title: '操作人',
          dataIndex: 'operation',
          key: 'operation'
        },
        {
          title: '处理时间',
          dataIndex: 'operationTime',
          key: 'operationTime'
        },
        {
          title: '处理说明',
          dataIndex: 'remark',
          key: 'remark'
        },
        {
          title: '操作',
          key: 'action',
          slots: {
            customRender: 'action'
          }
        }
      ],
      subType:null,
      dataSource: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      loading: false,
      loadingBtn:false,
      visible: false,
      check: true,
      formState: {
        type: [],
        child: [],
        remark: ''
      },
      form: {}
    })
    const searchMsg = ref({})
    const layout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    }
    const opsCheck = (id,record) => {
      state.form = {}
      state.form.orderId = '获取中'
      state.form.vinNo = '获取中'
      state.form.vinNoNew = '获取中'
      state.visible = true
      state.subType = record.subType.value
      query(id).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          state.form = res.data
          state.form.id = id
          detail(state.form.orderId, {}).then(res => {
            if (res.code === 10000) {
              state.form.pushTime = res.data.order.pushTime
              state.form.order = res.data.order
              state.form.orderCustomer = res.data.orderCustomer
              state.form.oldOrderVehicles = res.data.orderVehicles

            }
          })
        }
      }).finally(() => {
      })
    }
    const handleOk = () => {
      if (!state.check) {
        state.visible = false
      }
    }
    const audit = () => {
      let type = state.form.audit
      if (type === 2) {
        if (state.form.remark === '' || state.form.remark === null || state.form.remark === undefined) {
          message.warn('拒绝请填写原因')
          return
        }
      }
       state.loadingBtn = true
      editAudit({
        audit: type,
        opsId: state.form.id,
        remark: state.form.remark
      }).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          message.info('审核成功')
          state.visible = false
        }
      }).finally(() => {
        state.loadingBtn = false
        loadData()
      })
    }
    const handleTableChange = (e) => {
       state.pagination = e
       loadData()
    }
    const loadData = () => {
      state.loading = true
      opsPage({
        ...searchMsg.value,
        audit: 1,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          state.dataSource = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const searchAdd = (e) => {
      state.pagination.current = 1
      searchMsg.value = e
      loadData()
    }
    const resetBtn = () => {
      state.pagination.current = 1
      searchMsg.value = {}
      loadData()
    }
    onMounted(() => {
      loadData()
    })
    const iptData = ref([
      {
        type: "select",
        placeholder: "分类",
        value:null,
        prop: "subType",
        width: 200,
        opt: [
          {
            name:'修改车架号',
            id:8
          },
          {
            name:'修改客户',
            id:22
          },
          {
            name:'修改单台运费',
            id:19
          },
          {
            name:'修改分单员',
            id:25
          },
          {
            name:'修改录单员',
            id:26
          },

        ]
      },
      {
        type: "select",
        placeholder: "状态",
        value: null,
        prop: "status",
        width: 200,
        opt: [
          {
            name:'申请',
            id:0
          },
          {
            name:'拒绝',
            id:2
          },
          {
            name:'处理完成',
            id:9
          },
        ]
      },
      {
        type: "input",
        placeholder: "发起人",
        value: null,
        prop: "sponsor",
        width: 200,
      },
    ])
    return {
      ...toRefs(state),
      layout,
      loadData,
      handleOk,
      opsCheck,
      audit,
      handleTableChange,
      iptData,
      searchAdd,
      resetBtn
    }
  }
}
</script>

<style></style>
