<template>
<div>
  <a-form :model="form" layout="vertical">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="订单号">
            {{ form.orderId }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="老的车牌/车架号">
           <div v-for="item in  form.oldOrderVehicles" :key="item.id" class="m-r1">
            <span v-if="item.orderVehicleId === form.orderVehicleId">
              {{ item.vinNo }}
            </span>
           </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="18">
          <a-form-item label="新的车牌/车架号">
            {{ form.vinNoNew }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="原因">
            {{ form.reason }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24" style="backgroundColor:#11acb1;font-weight: bold">
          <a-form-item label="系统信息">
            推送时间：{{ form.pushTime }}
            <template v-for="(item, index) in  form.oldOrderVehicles" :key="index">
              <tr>
                第{{ index + 1 }}辆
              </tr>
              <tr>
                <td class="label-td">车型</td>
                <td>{{ item.brand }} · {{ item.model }}</td>
              </tr>
              <tr>
                <td class="label-td">车牌/车架号</td>
                <td>{{ item.vinNo }}</td>
              </tr>
              <td class="label-td">状况</td>
              <td>{{ item.status.label }}</td>
              <tr>
                <td class="label-td">保险公司</td>
                <td>{{ item.insuranceCompany.label }}</td>
              </tr>
              <tr>
                <td class="label-td">保额（万元）</td>
                <td>{{ item.insuranceFee }}</td>
              </tr>
            </template>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
</div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  form: {
    type: Object,
    default: {}
  }
})

</script>

<style lang="less" scoped>

</style>