<template>
  <div>
    <div class="box">
      <div style="margin-top:-20px">订单号：{{ form.orderId}}</div>
      <div class="m-t2">原客户</div>
      <div>名称：{{form.orderCustomer?.customerName}}</div>
      <div>证件号：{{form.orderCustomer?.certificateNumber}}</div>
      <div>电话：{{form.orderCustomer?.customerMobile}}</div>
    </div>
    <div class="box">
      <div>更改结果</div>
      <div class="m-t1">名称：{{  form.customerName }}</div>
      <div class="m-t1">电话：{{ form.customerMobile }}</div>
      <div class="m-t1">原因：{{ form.reason }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  form: {
    type: Object,
    default: {}
  }
})
</script>

<style lang="less" scoped>
.box {
  padding: 20px 10px;
  border-bottom: 1px solid #ccc;
}
</style>