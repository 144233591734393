<template>
  <div>
    <div class="box">
      <div>订单号{{ form.orderId }}</div>
      <div>
        <div class="text flex ju-end">注：如果一个订单有多个车，这里的列表只展示提交时勾选的车辆</div>
        <a-table ref="tableRef" :dataSource="form.orderVehicles" :rowKey="item => item.orderVehicleId" :columns="columns" size="small" :pagination="false" :scroll="{y:200}">
          <template #vinNo="{ record }">{{ record.vinNo }}{{ record.brand }}{{ record.model }}&nbsp;{{ record.vehicleType?.label }}</template>
          <template #insuranceCompany="{ record }">
            {{ record.insuranceCompany?.label }}￥{{ record.insuranceFee }}万元&nbsp;&nbsp;&nbsp;￥{{ record.insurancePremium }}
          </template>
          <template #freight="{ record }">
            ￥{{ record.freight }}
          </template>
          <template #pickType="{ record }">
            <div v-if="record.placeInAddress">
              ￥{{ record.placeInFee }}&nbsp;{{ record.pickType?.label }}&nbsp;{{ record.placeInAddress }}
            </div>
            <div v-else>
              不取车
            </div>
          </template>
          <template #takeType="{ record }">
            <div v-if="record.takeOutAddress">
              ￥{{ record.takeOutFee }}&nbsp;{{ record.takeType?.label }}&nbsp;{{ record.takeOutAddress }}
            </div>
            <div v-else>
              不送车
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <div class="box">
       <div class="m-t1">更改结果：</div>
       <div class="m-t1">运费（每台）：{{ form.transFee }}元</div>
       <div class="m-t1">取车费（每台）：{{ form.pickFee }}元</div>
       <div class="m-t1">送车费（每台）：{{ form.takeFee }}元</div>
       <div  class="m-t1">原因：{{  form.reason}}</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  form: {
    type: Object,
    default: {}
  }
})
const columns = ref([
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
  },
  {
    title: '车辆状态',
    dataIndex: 'status.label',
    align: 'center',
  },
  {
    title: '运费',
    dataIndex: 'freight',
    slots: {
      customRender: 'freight'
    },
    align: 'center',
  },
  {
    title: '保险信息',
    dataIndex: 'insuranceCompany',
    slots: {
      customRender: 'insuranceCompany'
    },
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'pickAppointTime',
    slots: {
      customRender: 'pickAppointTime'
    },
    align: 'center',
  },
  {
    title: '取车信息',
    dataIndex: 'pickType',
    slots: {
      customRender: 'pickType'
    },
    align: 'center',
  },
  {
    title: '送车信息',
    dataIndex: 'takeType',
    slots: {
      customRender: 'takeType'
    },
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.text {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
}

.box{
  padding: 0 10px 20px 10px;
  border-bottom: 1px solid #ccc;
}
</style>